<template>
  <OneColumnTemplate>
    <template v-slot:ColumnContent>
      <div
        v-if="FAQ.categories != null && FAQ.categories.length != 0"
        class="PageContentWrapper"
      >
        <div class="FAQBlock">
          <span id="PageTitle" class="PageTitle noselect">Часто задаваемые вопросы</span>
          <div class="FAQBody">
            <div class="FAQWrapper">
              <div class="FAQCategories noselect">
                <div
                  v-for="(cat, cindx) in FAQ.categories"
                  :key="cindx"
                  :class="{
                    FAQCat: true,
                    Active: cat.id === FAQ?.selectedCategoryObject?.id,
                  }"
                  @click="
                    () => {
                      FAQ.selectedCategoryObject = cat;
                      scrollToTop();
                    }
                  "
                >
                  <span>{{ cat.title }}</span>
                </div>
              </div>
            </div>

            <div class="FAQuestions">
              <SearchInput
                placeholder="Поиск по вопросам"
                :searchAttribures="['title', 'desc']"
                :originalArray="
                  FAQ.selectedCategoryObject == null
                    ? []
                    : FAQ.selectedCategoryObject.faqs
                "
                :setNewArrayCallback="handleArrayUpdate"
                :searchTextVal="FAQ.search"
                :postFiltersDataChanges="handleFilterChanges"
                :key="'SortingFiltersSearch-' + faqs_hash"
              />

              <div class="Results" :key="'SortingFiltersResult-' + faqs_filtered_hash">
                <details
                  v-for="(faq, findx) in FAQ.FilteredFaqs"
                  :key="findx"
                  class="QABlock ql-snow"
                >
                  <summary class="noselect">
                    <span>{{ faq.title }}</span>
                    <div class="ArrowUp"></div>
                  </summary>

                  <span class="FAQDesc ql-editor" v-html="faq.desc" />
                </details>
              </div>

              <span v-if="FAQ.FilteredFaqs.length === 0" class="noContent"
                >Список пуст</span
              >

              <span class="NoAQ"
                >Не нашли ответ на свой вопрос?
                <a href="https://t.me/octagon_help_bot" target="_blank" class="NoAQLink">
                  Задайте свой вопрос</a
                ></span
              >
            </div>
          </div>
        </div>
      </div>
      <span v-else class="noContent">На данный момент здесь ничего нет</span>
    </template>
  </OneColumnTemplate>
</template>

<script>
import { getAllFAQ } from "@/api/faq.js";

import OneColumnTemplate from "@/components/ProfileTemplates/OneColumn.vue";
import SearchInput from "@/components/CustomElements/SearchInput.vue";

export default {
  name: "FAQPage",
  components: {
    OneColumnTemplate,
    SearchInput,
  },
  computed: {
    faqs_hash() {
      if (this.FAQ.selectedCategoryObject == null) {
        return `#`;
      }

      let a = this.FAQ.selectedCategoryObject.faqs.map(
        (x, xindx) => `${xindx}-${x.id}-${x.title}-${x.cat_id}-${x.desc}`
      );
      return `${this.FAQ.selectedCategoryObject.faqs?.length}-${a.join("-")}`;
    },
    faqs_filtered_hash() {
      let a = this.FAQ.FilteredFaqs.map(
        (x, xindx) => `${xindx}-${x.id}-${x.title}-${x.cat_id}-${x.desc}`
      );
      return `${this.FAQ.FilteredFaqs?.length}-${a.join("-")}`;
    },
  },
  data() {
    return {
      FAQ: {
        categories: [],
        selectedCategoryObject: null,
        FilteredFaqs: [],
        search: "",
      },
    };
  },
  async created() {
    let _FAQ_response = await getAllFAQ();
    let filteredList = _FAQ_response.data;

    let _faqs_general_default = [];

    if (filteredList != null && filteredList.length != 0) {
      this.FAQ.categories = filteredList.filter((x) => x.faqs.length != 0);

      if (this.FAQ.categories.length != 0) {
        let _faqs_array = filteredList.map((cat) => cat.faqs);

        _faqs_general_default = _faqs_array.reduce((accumulator, current) => {
          return accumulator.concat(current);
        }, []);
      }
    }

    this.FAQ.categories.unshift({
      id: "all",
      title: "Все",
      faqs: _faqs_general_default,
    });
    this.FAQ.selectedCategoryObject = this.FAQ.categories[0];
  },
  methods: {
    scrollToTop() {
      // Находим элемент якорного блока
      const scrollToMe = document.querySelector(".PageContentWrapper");

      // Пролистываем страницу до якорного блока
      scrollToMe.scrollTo({
        top: 0,
        behavior: "smooth", // Добавляем плавную анимацию скролла
      });
    },

    handleArrayUpdate(UpdatedArray) {
      this.FAQ.FilteredFaqs = UpdatedArray;
    },
    handleFilterChanges(Val) {
      this.FAQ.search = Val;
    },
  },
};
</script>

<style scoped>
.PageContentWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;

  width: 100%;
  height: 100%;

  padding-top: 136px;
  padding-right: 15px;

  overflow: auto;
}
.FAQBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: fit-content;
  max-width: 100%;
  height: fit-content;

  margin: 0% auto;
}
.PageTitle {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffff;
}
.FAQBody {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;
}

.FAQWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 0;

  width: 264px;
  height: fit-content;
}
.FAQCategories {
  position: fixed;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 0;

  width: 264px;
  height: fit-content;
}
.FAQCat {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 16px 20px;

  width: 100%;
  height: fit-content;

  border-radius: 12px;
  background-color: transparent;

  transition: 0.2s;
}
.FAQCat > span {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
.FAQCat.Active {
  background-color: #1e1b4a;
}

.FAQuestions {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: 840px;
  height: fit-content;
}

.Results {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: 100%;
  height: fit-content;
}

.QABlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;

  padding: 20px;

  width: 100%;
  height: fit-content;

  border-radius: 12px;
  background-color: #1e1b4a;
}
.QABlock > summary {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  width: 100%;
  height: fit-content;

  list-style: none;
}
.QABlock > summary > span {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.QABlock > span {
  margin-top: 24px;
}

.QABlock[open] .ArrowUp {
  rotate: 0deg;
}

.ArrowUp {
  position: relative;
  display: block;

  width: 24px;
  height: 24px;

  background: url("./../assets/img/ReDesign/interface_icons/arrow_up.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  rotate: 180deg;
  transition: 0.2s;
}

.NoAQ {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #d3caffb2;
}
.NoAQ > .NoAQLink {
  cursor: pointer;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #656bff;
  text-decoration: none;
}

.noContent {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin: auto;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffff;
}
.FAQDesc {
  position: relative;
  display: block;

  padding: 0%;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #ffffffe5;
}
</style>

<style scoped>
@media (max-width: 768px) {
  .FAQBlock,
  .FAQBody {
    margin: 0;
    width: 100%;
  }

  .FAQWrapper {
    flex-shrink: 0;
    width: 100%;
    overflow-x: auto;
  }
  .FAQuestions {
    flex-shrink: 1;
    width: 100%;
  }

  .FAQBody {
    flex-direction: column;
  }
  .FAQCategories {
    position: relative;
    flex-direction: row;
    width: auto;
    flex-shrink: 0;
    gap: 16px;

    padding-bottom: 10px;
  }
  .FAQCat {
    flex-shrink: 0;
    width: fit-content;
    background-color: #1e1b4a;
  }
  .FAQCat.Active {
    background-color: #2c286d;
  }

  .QABlock > summary > span {
    font-size: 16px;
    line-height: 20px;
  }
  .FAQDesc {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .FAQBlock,
  .FAQBody {
    margin: 0;
    width: 100%;
  }

  .FAQWrapper {
    flex-shrink: 0;
  }
  .FAQuestions {
    flex-shrink: 1;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .PageContentWrapper {
    padding-top: 9px;
    padding-right: 0px;
    padding-bottom: 100px;
  }
}
</style>
